import { Member } from './member';
import {MEMBER_STATUS_INACTIVE} from "../constants";

export enum Role {
  Member = 'Member',
  Admin = 'Admin'
}

export interface User {
  id: string;
  username: string;
  password: string;
  memberId: string;
  members: Member[];
  selectedMember?: Member;
  isAccountActive: boolean;
  role: Role;
}

export const getUserFullName = (user: User): string => {
  if (!user) return '';

  if (!user.members || !user.members.length) return user.username;

  return user.selectedMember
    ? `${user.selectedMember.firstName} ${user.selectedMember.lastName}`
    : `${user.members[0].firstName} ${user.members[0].lastName}`
}

export const isInactiveSelectedMember = (user: User): boolean => {
  return user?.selectedMember?.statusId === MEMBER_STATUS_INACTIVE;
}
