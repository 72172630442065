import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  GridItem,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react';
import ProductsToPayList from '../components/products-to-pay-list';
import { ProductToPay } from '../models/product-to-pay';
import { useNavigate } from 'react-router-dom';
import MembershipFeeCard from '../components/membership-fee-card';
import {getActivitiesForMembershipFeeByMemberId, getMembershipFeesByMember} from '../services/product-service';
import { useAuth } from '../components/auth';
import { Member } from '../models/member';
import { MembershipFeesResponse } from '../models/web/membership-fee-response';
import { PeriodicProductPayment } from "../models/periodic-product-payment";
import PaymentMethods from "../components/payment-methods";
import {PeriodicProduct} from "../models/periodic-product";
import ActivitiesFeesForMembershipFee from "../components/activities-fees-for-membership-fee";
import {getProductToPayFromPeriodicProduct, getProductToPayFromPeriodicProductPayment} from "../utils/common";
import {getMember} from "../services/member-service";
import { Activity } from '../models/activity';
import {manualCheckStatusNotPayable} from "../helpers";

function MyMembershipFees(): JSX.Element {
  const auth = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [showButtons, setShowButtons] = useState(false);
  const [member, setMember] = useState<Member | null>(auth?.user?.selectedMember);
  const [membershipFeesResponse, setMembershipFeesResponse] = useState<MembershipFeesResponse | null>(null);
  const [membershipFeesPaymentData, setMembershipFeesPaymentData] = useState<
    Map<string, PeriodicProductPayment[]>
  >(new Map());
  const [membershipFeesPaymentDataYears, setMembershipFeesPaymentDataYears] =
    useState<string[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [membershipFeesToPayList, setMembershipFeesToPayList] = useState<
    ProductToPay[]
  >([]);
  const [membershipFeesToPayMessage, setMembershipFeesToPayMessage] = useState('Estamos cargando los datos... Un segundo y estamos... ⏳');
  const [showOldPaymentsWarning, setShowOldPaymentsWarning] = useState<boolean>(false);
  const [activitiesFees, setActivitiesFees] = useState<PeriodicProduct[]>([]);
  const [memberActivities, setMemberActivities] = useState<Activity[]>([]);
  const [showActivities, setShowActivities] = useState(true);

  useEffect(() => {
    if (member === null || !isLoading) return;

    const getMembershipFees = async (): Promise<void> => {
      const response = (await getMembershipFeesByMember(member.id)).response;
      if (response !== null) {
        setMembershipFeesResponse(response);
      }
      setIsLoading(false);
    }

    const getMemberActivities = async (): Promise<void> => {
      const response = (await getMember(member.id!))!;
      if (response !== null && response.activities) {
        setMemberActivities(response.activities);
      }
    }

    getMembershipFees().catch(() => {});
    getMemberActivities().catch(() => {});
  });

  useEffect(() => {
    if (membershipFeesResponse === null) return;

    setMembershipFeesToPayMessage('Estas son tus cuotas sociales. ¡Estás al día! Actualmente no tenés cuotas sociales pendientes de pago 👏 ¡Gracias!');
    const keys = Object.keys(membershipFeesResponse.membershipFeesPayments);
    const entries = Object.entries(membershipFeesResponse.membershipFeesPayments);
    const map = new Map(entries);
    setMembershipFeesPaymentData(map);
    setMembershipFeesPaymentDataYears(keys);

    if (membershipFeesResponse.pendingMembershipFees > 0) {
      setMembershipFeesToPayMessage(
        'Estas son tus cuotas sociales. Tenés cuotas sociales pendientes de pago. Para abonarlas, seleccioná aquellas cuotas que quieras pagar, clickeando sobre la casilla "Abonar".'
      );
      setShowOldPaymentsWarning(membershipFeesResponse.showOldPaymentsWarning ?? false);
      setShowButtons(true);
    }
  }, [membershipFeesResponse]);

  useEffect(() => {
    if (membershipFeesResponse == null) { return; }

    const index = membershipFeesResponse.firstNonPaidMembershipFeeYear != null
      ? membershipFeesPaymentDataYears.indexOf(membershipFeesResponse.firstNonPaidMembershipFeeYear.toString())
      : membershipFeesPaymentDataYears.length - 1;
    setTabIndex(index);
  }, [membershipFeesPaymentDataYears])


  const addMembershipFeeToList = async (
    membershipFee: PeriodicProductPayment
  ): Promise<void> => {
    let activitiesFeesForMembershipFee: PeriodicProductPayment[] | null = [];
    if (showActivities) {
      activitiesFeesForMembershipFee = (await getActivitiesForMembershipFeeByMemberId(member?.id!, membershipFee.product.id!)).response;
      activitiesFeesForMembershipFee = activitiesFeesForMembershipFee?.filter(x => !x.isCompleted) ?? [];
      const newActivitiesFees = activitiesFees.concat(activitiesFeesForMembershipFee?.map(x => x.product) ?? []);
      setActivitiesFees(newActivitiesFees);
    }

    const membershipFeeToPay = getProductToPayFromPeriodicProductPayment(membershipFee);

    const index = membershipFeesToPayList?.findIndex(
      (existingElement: { productId: string }) =>
        existingElement?.productId === membershipFee?.product.id
    );

    if (index > -1) {
      // Removing
      const membershipFeesListWithoutElement = membershipFeesToPayList.filter((v, i) => i < index);
      setMembershipFeesToPayList(membershipFeesListWithoutElement);
      setActivitiesFees([]);
    } else {
      // Adding
      const activitiesFeesToPay = [];
      for (const activityFee of activitiesFeesForMembershipFee ?? []) {
        activitiesFeesToPay.push(getProductToPayFromPeriodicProductPayment(activityFee));
      }

      membershipFeesToPayList.push(membershipFeeToPay);
      const newToPayList = membershipFeesToPayList.concat(activitiesFeesToPay);
      setMembershipFeesToPayList(newToPayList);
    }
  };

  const getMembershipFees = (
    year: string
  ): JSX.Element | JSX.Element[] | undefined => {
    let firstFound = false;
    let isFirstPayable = false;
    return membershipFeesPaymentData.has(year) &&
      membershipFeesPaymentData.get(year) != null ? (
      membershipFeesPaymentData
        .get(year)
        ?.map((membershipFee: PeriodicProductPayment, index: React.Key) => {
          if (!firstFound && membershipFee.isApplicable && membershipFee.product.paymentDetails?.length === 0) {
            firstFound = true;
            isFirstPayable = true;
          }
          else {
            isFirstPayable = false;
          }
          return(
          <MembershipFeeCard
            key={index}
            membershipFee={membershipFee}
            toPayList={membershipFeesToPayList}
            onChange={addMembershipFeeToList}
            isFirstPayable={isFirstPayable}
          />)
        })
    ) : (
      <></>
    );
  };

  const getTabForEachYear = (): JSX.Element | JSX.Element[] => {
    if (membershipFeesPaymentDataYears.length === 0) {
      return <></>;
    }

    return membershipFeesPaymentDataYears.map((year: string) => (
      <Tab key={year}>{year}</Tab>
    ));
  };

  const getTabPanelForEachYear = (): JSX.Element | JSX.Element[] => {
    if (membershipFeesPaymentDataYears.length === 0) {
      return <></>;
    }

    return membershipFeesPaymentDataYears.map((year: string) => (
      <TabPanel key={year}>
        <SimpleGrid columns={[2, 2, 6]} spacing={10}>
          {getMembershipFees(year)}
        </SimpleGrid>
      </TabPanel>
    ));
  };

  const getMembershipFeesToPay = (): JSX.Element => {
    if (membershipFeesToPayList.length === 0) {
      return <></>;
    }

    let title = "Cuotas a abonar";
    let text = "Revisá atentamente. Vas a abonar las siguientes cuotas sociales:";
    if (activitiesFees && showActivities) {
      title = "Cuotas y actividades a abonar";
      text = "Revisá atentamente. Vas a abonar las siguientes cuotas sociales y aranceles de actividades:";
    }

    return (
      <VStack width={['100%', '100%', '75%']} alignContent="flex-start" display="inline-grid">
        <Heading as="h2" size="md">{title}</Heading>
        <Text>{text}</Text>
        <ProductsToPayList
          membershipFeesToPay={membershipFeesToPayList}
          tableSize="sm"
        />
      </VStack>
    );
  };

  const handleClickPayAllButton = (): void => {
    const toPayList = [];
    for (const year of membershipFeesPaymentDataYears) {
      const entries = membershipFeesPaymentData.get(year);
      for (const membershipFeePaymentData of entries ?? []) {
        if (
          membershipFeePaymentData?.isApplicable &&
          membershipFeePaymentData?.remainingPriceToPay > 0 &&
          !manualCheckStatusNotPayable(membershipFeePaymentData)
        ) {
          const membershipFeeToPay: ProductToPay = {
            productId: membershipFeePaymentData.product.id!,
            name: membershipFeePaymentData.product.name,
            description: membershipFeePaymentData.product.description,
            price: membershipFeePaymentData.product.price,
            periodicProductPayment: membershipFeePaymentData
          };
          toPayList.push(membershipFeeToPay);
        }
      }
    }
    setMembershipFeesToPayList(toPayList);
  };

  const handleRemoveAllButton = (): void => setMembershipFeesToPayList([]);

  const handleChange = (index: number): void => {
    setTabIndex(index);
  };

  const handlePayButton = (): void => {
    navigate('/payment-page', {
      state: { productsToPay: membershipFeesToPayList, paymentMethod: 'MercadoPago' }
    });
  };

  const showSpinner = (): JSX.Element => {
    return (
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='blue.500'
        size='xl'
      />
    )
  }

  const getOldPaymentsWarning = () => {
    if (!showOldPaymentsWarning) return;

    return (
      <Alert status='error'>
        <AlertIcon />
        <AlertTitle>Atención</AlertTitle>
        <AlertDescription>Según el sistema, tenés cuotas sociales previas a agosto de 2022 pendientes de pago. Sin embargo, no te preocupes: esto podría ser un error nuestro.
          Estamos trabajando duro para actualizar nuestra base de datos. Si el sistema no refleja tu situación real, simplemente ignorá las cuotas sociales que ya tenés abonadas.
        </AlertDescription>
      </Alert>
    )
  }

  const removeActivitiesFeesFromList = () => {
    setMembershipFeesToPayList(membershipFeesToPayList.filter(x => x.periodicProductPayment?.product.discriminator === "MembershipFee"));
    setShowActivities(false);
  }

  return (
    <VStack
      width="full"
      align="center"
      spacing={3}
    >
      <VStack width="100%" align="flex-start" spacing={3}>
        <Heading>Mis cuotas</Heading>
        <Text>{membershipFeesToPayMessage}</Text>
        {getOldPaymentsWarning()}
      </VStack>
      {
        isLoading
          ? showSpinner()
          : (
            <>
              <VStack width="100%" paddingTop={2}>
                <Tabs
                  isFitted
                  align="start"
                  size="md"
                  variant="soft-rounded"
                  index={tabIndex}
                  onChange={handleChange}
                >
                  <TabList>{getTabForEachYear()}</TabList>
                  <TabPanels>{getTabPanelForEachYear()}</TabPanels>
                </Tabs>
              </VStack>
              <HStack
                alignContent="center"
                width={['100%', '100%', '30%']}
                paddingTop={3}
              >
                {showButtons ? (
                  <>
                    <Button
                      width="50%"
                      alignSelf="center"
                      colorScheme="blue"
                      onClick={handleClickPayAllButton}
                    >
                      Seleccionar todas
                    </Button>
                    <Button
                      width="50%"
                      colorScheme="blue"
                      onClick={handleRemoveAllButton}
                    >
                      Remover todas
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </HStack>
              <SimpleGrid columns={[1, 1, 2]} width="100%" paddingTop={[5, 5, 10]} spacing={[5, 5, 0]}>
                <GridItem>
                  {getMembershipFeesToPay()}
                  {
                    activitiesFees.length > 0 && showActivities
                      ? <ActivitiesFeesForMembershipFee activities={memberActivities} buttonActionOnClick={removeActivitiesFeesFromList}/>
                      : <></>
                  }
                </GridItem>
                <GridItem>
                  {
                    membershipFeesToPayList.length > 0
                      ? <PaymentMethods membershipFeesToPayList={membershipFeesToPayList}/>
                      : <></>
                  }
                </GridItem>
              </SimpleGrid>
            </>
          )
      }
    </VStack>
  );
}

export default MyMembershipFees;
