import React, { ReactElement, useEffect, useState } from 'react';
import * as authService from '../services/user-service';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUser, removeUserSession, setUserSession } from '../utils/user-session';
import { Center, CircularProgress, useToast } from '@chakra-ui/react';
import type { Member } from '../models/member';
import {isInactiveSelectedMember, User} from '../models/user';
import {
  LINK_MEMBER_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE, MEMBER_STATUS_INACTIVE, INACTIVE_MEMBER_PAGE
} from '../constants';
import { LoginResponse } from '../models/web/login-response';
import { HttpRequestResponse } from '../models/web/http-request-response';
import { Auth } from '../models/auth';

export const AuthContext = React.createContext(null as any);

export const useAuth = (): any => React.useContext(AuthContext);

export const AuthProvider = ({
  children
}: {
  children: React.ReactNode;
}): any => {
  const [user, setUser] = useState<User | null>(getUser());
  const [token, setToken] = useState<string | null>();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isClosingSession, setIsClosingSession] = useState(false);

  useEffect(() => {
    if (user !== null) {
      setUserSession(user, token);
    }
    else {
      removeUserSession();
    }
  }, [user, token]);

  const login = async (
    user: string,
    password: string
  ): Promise<HttpRequestResponse<LoginResponse>> => {
    const response = await authService.signin(user, password);
    if (response.isSuccessful) {
      const loginResponse = response.response!;
      loginResponse.user.selectedMember = loginResponse.user.members[0];
      setUser(loginResponse.user);
      setToken(loginResponse.token);
      setIsLoggedIn(true);
    }
    return response;
  };

  const linkMember = (member: Member): void => {
    const user = getUser();
    if (!user) return;

    if (!user.members.find(m => m.id === member.id)) {
      user.members.push(member);
    }

    user.selectedMember = member;
    setUser(user);
  };

  const logout = async (callback: Promise<any>): Promise<void> => {
    setUser(null);
    setIsLoggedIn(false);
    setIsClosingSession(true);
    if (callback != null) {
      await callback;
    }
  };

  const value: Auth | null = {
    user,
    login,
    logout,
    linkMember,
    isLoggedIn,
    isClosingSession,
    setUser: (value: User): void => {
      console.log('setUser', value);
      setUser(value);
    }
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const RequireAuth = ({
  children
}: {
  children: ReactElement;
}): JSX.Element => {
  const auth: Auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const toastId = 'no-auth-toast';

  useEffect(() => {
    if (auth.user == null) {
      if (!toast.isActive(toastId) && !auth.isClosingSession) {
        toast({
          id: 'no-auth-toast',
          title: 'Tenemos un problema 🔌',
          description:
            'No podés acceder a esta página ⛔ Primero tenés que iniciar sesión 👮‍',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-right'
        });
      }
      navigate(LOGIN_PAGE_ROUTE, {
        state: { from: location },
        replace: true
      });
    }
    setLoading(false);
  }, [auth.user, auth.isClosingSession, toast, location, navigate]);

  if (loading) {
    return (
      <Center>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  return children;
};

export const RequireMembership = ({
  children,
}: {
  children: ReactElement;
}): JSX.Element => {
  const auth: Auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const toastId = 'non-member-toast';

  useEffect(() => {
    if ((auth.user?.members && isInactiveSelectedMember(auth.user!)) && location.pathname !== LINK_MEMBER_PAGE_ROUTE) {
      if (!toast.isActive(toastId) && !auth.isClosingSession) {
        toast({
          id: toastId,
          title: 'Tenemos un problema 🔌',
          description: 'No podés acceder a esta página ⛔ Estás dado de baja como socio del club.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-right'
        });
      }
      navigate(INACTIVE_MEMBER_PAGE, {
        state: { from: location },
        replace: true
      });
    }

    if ((!auth.user?.members || auth.user?.members?.length === 0) && location.pathname !== LINK_MEMBER_PAGE_ROUTE) {
      if (!toast.isActive(toastId) && !auth.isClosingSession) {
        toast({
          id: toastId,
          title: 'Tenemos un problema 🔌',
          description:
            'No podés acceder a esta página ⛔ Primero tenés que enlazar tu cuenta a tu número de socio 🪪',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-right'
        });
      }
      navigate(LINK_MEMBER_PAGE_ROUTE, {
        state: { from: location },
        replace: true
      });
    }
    setLoading(false);
  }, [auth.isClosingSession, auth.user?.selectedMember, location, navigate, toast]);

  if (loading) {
    return (
      <Center>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  return children;
};

export const RequireNonAuth = ({
  children
}: {
  children: ReactElement;
}): JSX.Element => {
  const auth: Auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const toastId = 'member-not-required-toast';

  useEffect(() => {
    if (auth.user != null) {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: 'Tenemos un problema 🔌',
          description:
            'No podés acceder a esta página ⛔ Ya tenés un usuario y la sesión iniciada ✅',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-right'
        });
      }
      navigate('/dashboard', {
        state: { from: location },
        replace: true
      });
    }
    setLoading(false);
  }, [auth.user, auth.isLoggedIn, location, navigate, toast]);
  if (loading) {
    return (
      <Center>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  return children;
};

export const RequireNonMembership = ({
  children
}: {
  children: ReactElement;
}): JSX.Element => {
  const auth: Auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const toastId = 'member-not-required-toast';

  useEffect(() => {
    if (auth.user?.selectedMember !== null) {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: 'Tenemos un problema 🔌',
          description:
            'No podés acceder a esta página ⛔ Ya pasaste por este proceso antes y no es necesario que lo repitas ✅',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-right'
        });
      }
      navigate('/dashboard', {
        state: { from: location },
        replace: true
      });
    }
    setLoading(false);
  }, [auth.user?.selectedMember, auth.isLoggedIn, location, navigate, toast]);
  if (loading) {
    return (
      <Center>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  return children;
};
