import React from "react"
import AlertMessage from "../components/alert-message";
import {useAuth} from "../components/auth";
import {Auth} from "../models/auth";
import {getMemberFullName} from "../models/member";

export const InactiveMember = () => {
  const auth: Auth = useAuth();

  return (
    <>
      <AlertMessage status="error" title={"Dado de baja"} description={`El socio ${getMemberFullName(auth.user?.selectedMember!)} se encuentra dado de baja. Comunicate con la administración para regularizar la situación. Te pedimos disculpas por las molestias ocasionadas.`} hidden={false} />
    </>
  )
}
