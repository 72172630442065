import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import Login from './pages/login';
import Registration from './pages/registration';
import Dashboard from './pages/dashboard';
import FirstLogin from './pages/first-login';
import MyData from './pages/my-data';
import MyMembershipFees from './pages/my-membership-fees';
import {
  RequireAuth,
  RequireMembership,
  RequireNonAuth,
  useAuth
} from './components/auth';
import PaymentPage from './pages/payment-page';
import MyMembershipCredential from './pages/my-membership-credential';
import MemberStatus from './pages/member-status';
import {
  DASHBOARD_PAGE_ROUTE, INACTIVE_MEMBER_PAGE,
  LINK_MEMBER_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE, MY_ACTIVITIES_PAGE,
  MY_DATA_PAGE_ROUTE,
  MY_MEMBERSHIP_CREDENTIAL_PAGE_ROUTE,
  MY_MEMBERSHIP_FEES_PAGE_ROUTE,
  REGISTRATION_PAGE_ROUTE
} from './constants';
import MyActivities from './pages/my-activities';
import Reset from './pages/reset';
import ForgotPassword from "./pages/forgot-password";
import {InactiveMember} from "./pages/inactive-member";

function AppRouter(): JSX.Element {
  const auth = useAuth();

  return (
    <BrowserRouter basename={ process.env.NODE_ENV === 'production' ? '' : '' }>
      <Routes>
        <Route path="/" element={<App />}>
          {
            auth?.user == null
              ? (
                <Route
                  index
                  element={<Login />}
                />
              )
              : (
                <Route
                  index
                  element={<Dashboard />}
                />
              )
          }
          <Route
            path={INACTIVE_MEMBER_PAGE}
            element={
              <RequireAuth>
                <InactiveMember />
              </RequireAuth>
            }
          />
          <Route
            path={LOGIN_PAGE_ROUTE}
            element={
              <RequireNonAuth>
                <Login />
              </RequireNonAuth>
            }
          />
          <Route
            path={LINK_MEMBER_PAGE_ROUTE}
            element={
              <RequireAuth>
                <FirstLogin />
              </RequireAuth>
            }
          />
          <Route
            path={REGISTRATION_PAGE_ROUTE}
            element={
              <RequireNonAuth>
                <Registration />
              </RequireNonAuth>
            }
          />
          <Route
            path={DASHBOARD_PAGE_ROUTE}
            element={
              <RequireAuth>
                <RequireMembership>
                  <Dashboard />
                </RequireMembership>
              </RequireAuth>
            }
          />
          <Route
            path={MY_MEMBERSHIP_CREDENTIAL_PAGE_ROUTE}
            element={
              <RequireAuth>
                <RequireMembership>
                  <MyMembershipCredential />
                </RequireMembership>
              </RequireAuth>
            }
          />
          <Route
            path="/member-status/:id"
            element={
              <RequireAuth>
                <RequireMembership>
                  <MemberStatus />
                </RequireMembership>
              </RequireAuth>
            }
          />
          <Route
            path={MY_DATA_PAGE_ROUTE}
            element={
              <RequireAuth>
                <RequireMembership>
                  <MyData />
                </RequireMembership>
              </RequireAuth>
            }
          />
          <Route
            path={MY_MEMBERSHIP_FEES_PAGE_ROUTE}
            element={
              <RequireAuth>
                <RequireMembership>
                  <MyMembershipFees />
                </RequireMembership>
              </RequireAuth>
            }
          />
          <Route
            path={MY_ACTIVITIES_PAGE}
            element={
              <RequireAuth>
                <RequireMembership>
                  <MyActivities />
                </RequireMembership>
              </RequireAuth>
            }
          />
          <Route path="/payment-page" element={<PaymentPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset" element={<Reset />} />
          <Route
            path="*"
            element={
              <main style={{ padding: '1rem' }}>
                <p>No hay nada por acá!</p>
              </main>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
